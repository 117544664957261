<template>
    <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="ml-2">
            <b-img-lazy
                src="~/assets/images/logo/box-logo.png"
                class="flow-logo"
                fluid
            />
        </div>
        <h1 class="text-center text-s-bold text-28 mt-5">
            {{ $t('redirection_external.title') }}
        </h1>
        <p class="text-center text-20 mt-5 align-items-center">
            {{ $t('redirection_external.no_redirect_hint') }}
            <b-button
                variant="link"
                class="text-primary text-20 text-s-bold p-0 m-0 mb-1"
                @click="navigateToDestination"
            >
                {{ $t('redirection_external.no_redirect_click_here') }}
            </b-button>
        </p>
    </div>
</template>

<script>
import { getRegionByTimezone } from '@/services/dateServices'

export default {
    name: 'Index',
    asyncData({ route, error, redirect }) {
        try {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const region = getRegionByTimezone(timezone)
            const isAffiliateLink = route.query.a_aid
            const isUtmRedirect = route.query.utm_source
            let lang = 'en'
            if (region === 'hk' || region === 'mo') {
                lang = 'zh'
            }
            if (isAffiliateLink) {
                redirect(301, `/${region}/${lang}`)
            } else if (isUtmRedirect) {
                redirect(301, {
                    path: `/${region}/${lang}?utm_source=${isUtmRedirect}`
                })
            }
        } catch (err) {
            error(err)
        }
    },
    head() {
        return {
            meta: [
                {
                    name: 'robots',
                    content: 'noindex,nofollow'
                }
            ],
            ...this.postAffiliateProTracker
        }
    },
    mounted() {
        try {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const region = getRegionByTimezone(timezone)
            const isAffiliateLink = this.$route.query.a_aid
            const isUtmRedirect = this.$route.query.utm_source
            let lang = 'en'
            if (region === 'hk' || region === 'mo') {
                lang = 'zh'
            }
            if (isAffiliateLink) {
                this.$router.push({
                    path: `/${region}/${lang}`
                })
            } else if (isUtmRedirect) {
                this.$router.push({
                    path: `/${region}/${lang}?utm_source=${isUtmRedirect}`
                })
            }
        } catch (err) {
            this.$nuxt.error(err)
        }
    },
    methods: {
        navigateToDestination() {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const region = getRegionByTimezone(timezone)
            const isUtmRedirect = this.$route.query.utm_source
            let lang = 'en'
            if (region === 'hk' || region === 'mo') {
                lang = 'zh'
            }
            if (isUtmRedirect) {
                this.$router.push({
                    path: `/${region}/${lang}?utm_source=${isUtmRedirect}`
                })
            } else {
                this.$router.push({
                    path: `/${region}/${lang}`
                })
            }
        }
    }
}
</script>
<style scoped>
.flow-logo {
    width: 150px;
    height: auto;
    border-radius: 75px;
    border: 2px solid #000000;
}
</style>
